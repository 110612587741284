import React from "react"

import styles from "./text-page.module.scss"

const TextPageComponent = ({ title, children }) => (
  <div style={{ marginTop: 100 }}
       className={`container ${styles.page}`}>
    <h2>{title}</h2>
    {children}
  </div>
)

TextPageComponent.gallery = styles.gallery

export default TextPageComponent