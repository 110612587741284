import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextPageComponent from "../components/shared/text-page"
import { Link } from "gatsby"
import Email from "../components/shared/email"

const AgreementPage = () => (
  <Layout forceFixedHeader={true} page={"agreement"}>
    <SEO title="Лицензионный договор-оферта" noindex="noindex"/>
    <TextPageComponent
      title={"Лицензионный договор-оферта о предоставлении неисключительного права пользования Сервисом «Параплан»"}>
      <p>
        <b>Место:</b> г. Самара<br/>
        <b>Дата опубликования:</b> «31» июля 2018 года
      </p>
      <p>Настоящим документом <b>Общество с ограниченной ответственностью «Холмонт Бизнес
        Решения»</b>, именуемое в дальнейшем «Лицензиар», в лице директора Онучина Алексея
        Викторовича, действующего на основании устава, выражает намерение заключить договор о
        предоставлении неисключительного права пользования сервисом «Параплан» с Лицензиатами
        на условиях настоящей оферты (Далее — Договор).</p>
      <ol>
        <li>
          <h3>Термины и определения</h3>
          <ol>
            <li><b>Оферта</b> — условия настоящего соглашения (с учетом всех условий и дополнений,
              размещенным на страницах ресурса «Параплан» или сторонних ресурсах, и доступные по
              ссылкам, указанным в тексте настоящего договора).
            </li>
            <li><b>Акцепт Оферты</b> — полное и безоговорочное принятие Оферты. Акцепт Оферты создает
              Договор на условиях Оферты.
            </li>
            <li><b>Сервис</b> — это совокупность связанных между собой веб-страниц, размещенных в сети
              Интернет по уникальному адресу (URL): <Link to={"/"}>https://paraplancrm.ru</Link>, облачная система
              автоматизации, позволяющая Лицензиату хранить и обрабатывать данные своих клиентов, в соответствии с
              требованиями законодательства о защите персональных данных.
            </li>
            <li><b>Лицензиар</b> — владелец ресурса «Параплан» (<Link to={"/"}>https://paraplancrm.ru</Link>) — ООО
              «Холмонт Бизнес Решения» ИНН 6321416763, обладатель исключительных прав на Сервис «Параплан»,
              осуществляющий управление Сервисом и взаимодействие с Лицензиатами, а также
              совершающий иные действия, связанные с использованием Сервиса, предоставляющий за
              вознаграждение неисключительное право использования Сервиса в соответствии с условиями
              настоящего Договора.
            </li>
            <li><b>Лицензиат</b> — любое физическое или юридическое лицо, правомерно владеющие данными
              доступа к Сервису, на возмездной основе использующее Сервис способами, установленными
              настоящим договором, в пределах, установленных настоящим Договором.
            </li>
            <li><b>Неисключительная Лицензия</b> — предоставление Лицензиату права использования Сервиса с
              сохранением за Лицензиаром права выдачи лицензий другим лицам.
            </li>
            <li><b>Информация</b> — любые сведения и контент, размещенные на Сервисе.</li>
            <li><b>Учетная запись</b> — запись, хранящаяся на сайте Сервиса, содержащая сведения,
              необходимые для идентификации Лицензиата при предоставлении доступа для использования
              Сервиса, информацию для Авторизации и учета в Сервисе. К такой записи, в том числе
              относятся имя пользователя и пароль (или другие аналогичные средства аутентификации).
            </li>
            <li>Термины, специально не определенные в настоящем Договоре, используются в значениях,
              установленных нормативными правовыми актами Российской Федерации.
            </li>
          </ol>
        </li>
        <li>
          <h3>Предмет договора</h3>
          <ol>
            <li>Лицензиар предоставляет Лицензиату за вознаграждение, в порядке и на условиях,
              изложенных в настоящем Договоре, а также в соответствии с положениями части 4
              Гражданского Кодекса РФ, неисключительную лицензию на право использования Сервиса.
            </li>
            <li>
              Доступ к Сервису осуществляется посредством удаленного доступа, с использованием
              личной учетной записи Лицензиата по интернет-адресу: <Link to={"/"}>https://paraplancrm.ru</Link>
            </li>
            <li>
              Объем неисключительных прав, предоставляемых Лицензиату, ограничивается правом
              использования полезного функционала Сервиса, доступного Лицензиату исходя из выбранного
              им тарифного плана.
            </li>
            <li>Территория использования Сервиса: территории всех стран мира без ограничений.</li>
            <li>
              Срок действия неисключительных прав равен оплаченному Лицензиатом периоду
              использования Сервиса, но не менее одного месяца. Лицензиат вправе продлевать срок действия
              прав неограниченное количество раз посредством оплаты каждого нового срока. Лицензиат
              утрачивает право использования Сервиса в день, следующий за последним днем оплаченного
              периода прав использования Сервиса.
            </li>
            <li>
              Лицензиар имеет право изменять функциональность Сервиса без предварительного
              уведомления Лицензиата.
            </li>
          </ol>
        </li>
        <li>
          <h3>Гарантии сторон</h3>
          <ol>
            <li>Лицензиар гарантирует, что он является надлежащим правообладателем Сервиса. Лицензиар
              также гарантирует, что в Сервисе не используются элементы, нарушающие права третьих лиц
            </li>
            <li>Лицензиат в свою очередь гарантирует использование Сервиса в порядке и в сроки,
              установленные настоящим Договором.
            </li>
            <li>Размещая персональные, иные данные и сведения в Сервисе, Лицензиат дает согласие на то,
              что Лицензиар (и/или его уполномоченные представители) будет получать и хранить в
              электронном виде персональные данные Лицензиата и/или третьих лиц, включая (но не
              ограничиваясь) сотрудников, клиентов, контрагентов Лицензиата по поручению Лицензиата.
              Цель обработки указанных данных заключается в предоставлении возможности использования
              Сервиса, согласно условиям Договора.
            </li>
            <li>Лицензиат дает согласие на то, что Лицензиар будет направлять в адрес Лицензиата
              корреспонденцию на указанный им адрес электронной почты, информацию о проводимых
              Сервисом и его партнерами рекламных акциях и иную информацию.
            </li>
          </ol>
        </li>
        <li>
          <h3>Права и обязанности сторон</h3>
          <ol>
            <li>Под правом на использование Сервиса Стороны подразумевается передачу Лицензиату
              неисключительной, не подлежащей передаче и сублицензированию ограниченной лицензии на
              основе подписки на доступ и использование Сервиса, согласно условиям настоящего Договора.
            </li>
            <li>
              Лицензиат в процессе использования Сервиса обязуется:
              <ol>
                <li>
                  своевременно и в порядке, установленном настоящим Договором осуществлять
                  оплату лицензионного вознаграждения;
                </li>
                <li>
                  не нарушать исключительных прав на Сервис, принадлежащих Лицензиару на
                  праве собственности;
                </li>
                <li>
                  не передавать права использования Сервиса Сублицензиатам или иным третьим
                  лицам;
                </li>
                <li>не передавать данные доступа к Сервису третьим лицам;</li>
                <li>
                  допускать для работы с Сервисом в части обработки персональных данных только
                  работников, которым такой доступ необходим для исполнения ими своих служебных
                  обязанностей, назначенных приказом;
                </li>
                <li>
                  определить для каждого имеющего доступ к Сервису сотрудника роль безопасности
                  в рамках необходимого для работы минимума;
                </li>
                <li>менять пароль доступа к Сервису каждые 6 месяцев;</li>
                <li>хранить пароли доступа в секрете;</li>
                <li>
                  обеспечить контроль физического доступа в помещения, где происходит работа в
                  Сервисе;
                </li>
                <li>
                  обеспечить размещение устройств ввода информации в Сервис способом,
                  исключающим ее несанкционированный просмотр;
                </li>
                <li>
                  собирать и хранить согласия на обработку персональных данных субъектов
                  физических лиц, являющихся клиентами и сотрудниками Лицензиата, перед началом
                  обработки таких данных в Сервисе;
                </li>
                <li>
                  в случае технических неполадок, а также отсутствия доступа к Сервису, Лицензиат
                  обязуется незамедлительно обратиться в службу технической поддержки сервиса по
                  адресу электронной почты: <Email name={"sales"} domain={"paraplancrm"} tld={"ru"}/>.
                </li>
                <li>
                  в случае досрочного расторжения договора или отказа от его пролонгации,
                  Лицензиат обязан произвести копирование всех своих данных на собственный ресурс до
                  окончания срока действия прав. Лицензиар не гарантирует сохранность и не обеспечивает
                  возможность доступа Лицензиата к размещенной им в Сервисе информации по
                  окончании срока действия прав использования.
                </li>
              </ol>
            </li>
            <li>
              Лицензиар обязуется:
              <ol>
                <li>
                  обеспечивать круглосуточную и непрерывную работу Сервиса в сети Интернет, за
                  исключением времени проведения профилактических мероприятий, составляющих не
                  более 24 часов в месяц;
                </li>
                <li>
                  обеспечивать ежедневное резервное копирование данных Лицензиата. Срок
                  хранения резервных копий составляет 7 суток, восстановление утраченных данных
                  осуществляется по умолчанию по последней резервной копии, если стороны не
                  согласуют восстановление данных из более ранней копии;
                </li>
                <li>
                  обеспечивать хранение архива данных Лицензиата. Обязательства по хранению
                  данных Лицензиата ограничены сроком действия лицензии. При прекращении действия
                  лицензии вследствие отказа Лицензиата от ее пролонгации, информация хранится в
                  Сервисе в течение 1 (одного месяца) с момента окончания срока действия лицензии,
                  после чего данные удаляются без возможности восстановления;
                </li>
                <li>
                  обеспечивать сохранность данных, имеющих отношение к Лицензиату, и ни при
                  каких условиях не передавать их третьим лицам, за исключением поступивших в
                  установленном порядке запросов органов государственной власти;
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <h3>Лицензионное вознаграждения</h3>
          <ol>
            <li>
              Лицензиат уплачивает Лицензиару вознаграждение за предоставление права использования
              Сервиса.
            </li>
            <li>
              Стоимость и периоды оплаты вознаграждения за пользование Сервисом размещаются на
              сайте Лицензиара по адресу <Link to={"/"}>https://paraplancrm.ru</Link>.
            </li>
            <li>
              Лицензиат уплачивает Лицензиару 100% вознаграждения, указанного в выставленном
              Лицензиаром счете на оплату, путем безналичного перечисления денежных средств на
              банковский счет Лицензиара или банковской картой через сеть Интернет. Оплата выставленного
              счета является согласием Лицензиата на размер вознаграждения и обозначает безакцептное
              принятие данного Договора.
            </li>
            <li>
              Лицензиар оставляет за собой право изменять размер лицензионного вознаграждения,
              уведомив об этом Лицензиата в срок не позднее 10 дней до истечения оплаченного срока
              использования Сервиса посредством любого из следующих способов: SMS-сообщением на
              номер, предоставленный Лицензиатом в качестве основного контактного номера, по
              электронной почте или посредством опубликования информации об изменении размера
              вознаграждения на сайте Лицензиара по адресу <Link to={"/"}>https://paraplancrm.ru</Link>.
            </li>
            <li>
              Оплата нового периода пользования сервисом по измененным тарифам является согласием
              Лицензиата на размер вознаграждения и пролонгацию договора на измененных условиях.
              Оплата каждого нового периода осуществляется Лицензиатом самостоятельно, без выставления
              счета, посредством перечисления соответствующей суммы вознаграждения на счет Лицензиара.
            </li>
            <li>
              При неиспользовании Лицензиатом Сервиса как в целом, так и в части по причинам, не
              зависящим от Лицензиара, полученное лицензионное вознаграждение возврату не подлежит.
            </li>
          </ol>
        </li>
        <li>
          <h3>Безопасность учетной записи</h3>
          <ol>
            <li>
              Лицензиат обязуется отразить достоверную, полную и точную информацию о себе и
              поддерживать эту информацию в актуальном состоянии.
            </li>
            <li>
              Лицензиат согласен с тем, что безопасность пароля напрямую зависит от его сложности
              (количества и вариации символов).
            </li>
            <li>
              Лицензиат согласен с тем, что он самостоятельно несет ответственность за сохранение
              конфиденциальности логина и пароля, связанных с его личной учетной записью, используемым
              им для доступа к Сервису. Также Лицензиат согласен с тем, что он несет исключительную
              ответственность перед Лицензиаром за все действия, которые совершены при использовании его
              (Лицензиата) учетной записи.
            </li>
            <li>
              Активация учетной записи Лицензиата в Сервисе производится после оплаты
              лицензионного вознаграждения.
            </li>
            <li>
              В случае если Лицензиату станет известно о любом несанкционированном использовании
              его пароля, логина или реквизитов личной учетной записи, Лицензиат обязуется
              незамедлительно уведомить об этом Лицензиара, выслав соответствующее электронное
              сообщение по адресу: <Email name={"sales"} domain={"paraplancrm"} tld={"ru"}/>.
            </li>
            <li>
              Лицензиат обязуется не размещать (а в случае размещения — самостоятельно нести
              ответственность в полном объеме) в Сервисе адреса электронной почты, номера телефонов и прочую личную
              информацию любых третьих лиц без их личного согласия на такие действия.
            </li>
          </ol>
        </li>
        <li>
          <h3>Ответственность сторон</h3>
          <ol>
            <li>
              За неисполнение или ненадлежащее исполнение Сторонами обязательств, принятых на себя
              в соответствии с настоящим Договором, Стороны несут ответственность в соответствии с
              действующим российским законодательством и настоящим Договором.
            </li>
            <li>
              Лицензиар не несет ответственности за какие-либо убытки (в том числе убытки в связи с
              неполучением прибыли, утратой деловой информации, причинением любого имущественного
              ущерба), возникающие у Лицензиата в связи с использованием или с невозможностью
              использования им Сервиса.
            </li>
            <li>
              Лицензиар не несет ответственности за качество каналов связи, настройки интернет-браузеров, а также за
              перебои в работе, происходящие по причинам, не зависящим от Лицензиара.
            </li>
            <li>
              В случае нарушения Лицензиатом п. 4.2.1.—4.2.13. настоящего Договора Лицензиар вправе отменить право
              использования Сервиса Лицензиатом без предварительного уведомления и без возврата уплаченного Лицензиару
              вознаграждения.
            </li>
            <li>
              В случае если у Лицензиата есть подозрение несанкционированного использования Сервиса, он обязуется
              немедленно уведомить об этом Лицензиара для осуществления последним блокировки учетных записей Лицензиата
              до выяснения обстоятельств получения доступа к Сервису третьими лицами. Вплоть до получения указанного
              уведомления Лицензиар признает использование Сервиса с согласия Лицензиата.
            </li>
            <li>
              Лицензиат несет ответственность за любую информацию (в том числе, но не ограничиваясь: файлы с данными,
              тексты и т. д.), которую он предоставил для размещения в части Сервиса, к которой он может иметь доступ.
            </li>
            <li>
              При любых обстоятельствах ответственность Лицензиара в соответствии со статьей 15 Гражданского кодекса
              России не может превышать 5 000 (пяти тысяч) рублей и возлагается на него при наличии в его действиях
              вины.
            </li>
            <li>
              Положения настоящего Договора не исключают и не ограничивают ответственность Лицензиара за причинение
              убытков в той степени, в которой такая ответственность не может быть исключена или ее действие не может
              быть ограничено применимым законодательством.
            </li>
          </ol>
        </li>
        <li>
          <h3>Форс-мажор</h3>
          <ol>
            <li>
              Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение своих обязательств по
              настоящему Договору в случае действия обстоятельств непреодолимой силы, прямо или косвенно препятствующих
              исполнению настоящего Договора, то есть таких обстоятельств, которые не зависят от воли Сторон,
              чрезвычайные и непредотвратимые явления, события, воздействие которых происходит извне и не зависит от
              субъективных факторов, не могли быть предвидены Сторонами в момент заключения Договора и предотвращены
              разумными средствами при их наступлении (форс-мажор).
            </li>
            <li>
              К обстоятельствам, указанным в п. 8.1. настоящего Договора, относятся: стихийные бедствия, ураганы, сход
              снежных лавин, эпидемии, землетрясения, наводнения, иные природные катаклизмы, а также военные действия,
              крупномасштабные забастовки, запретительные меры государства, акты органов власти, непосредственно
              затрагивающие предмет настоящего Договора.
            </li>
            <li>
              К обстоятельствам непреодолимой силы не относятся финансово-экономический кризис, недофинансирование или
              иные финансовые затруднения одной из Сторон.
            </li>
            <li>
              В случае наступления обстоятельств непреодолимой силы срок исполнения обязанностей по настоящему Договору
              отодвигается на период действия этих обстоятельств.
            </li>
            <li>
              Если обстоятельства непреодолимой силы продолжают действовать более 2 (двух) месяцев подряд, каждая из
              Сторон вправе расторгнуть настоящий Договор в одностороннем порядке путем направления другой Стороне
              соответствующего уведомления. Данное условие действительно, если Сторона, подвергшаяся воздействию
              обстоятельств непреодолимой силы, уведомила другую Сторону о наступлении таких обстоятельств не позднее 5
              (пяти) дней с даты их начала. При этом Лицензиар вправе размещать такие уведомления на сайте Лицензиара по
              адресу <Link to={"/"}>https://paraplancrm.ru</Link>, и это является надлежащим способом уведомления
              Лицензиата.
            </li>
            <li>
              В случае спора о времени наступления, сроках и окончания определенных обстоятельств
              непреодолимой силы, заключение компетентного органа по месту нахождения соответствующей
              Стороны будет являться надлежащим и достаточным подтверждением начала, срока действия и
              окончания указанных обстоятельств.
            </li>
          </ol>
        </li>
        <li>
          <h3>Условия расторжения настоящего договора</h3>
          <ol>
            <li>
              Каждая из Сторон имеет право досрочно расторгнуть настоящий Договор путем
              направления уведомления не менее чем за 10 (десять) календарных дней до предполагаемой
              даты расторжения.
            </li>
            <li>
              В случае расторжения Договора со стороны Лицензиара при отсутствии виновных действий
              Лицензиата, Лицензиар производит компенсацию неиспользованного временного ресурса
              доступа к Сервису, пропорционально сумме вознаграждения за соответствующий период.
            </li>
            <li>
              В случае расторжения Договора со стороны Лицензиара по причине нарушений условий
              Договора Лицензиатом, возврат вознаграждения не производится.
            </li>
            <li>
              В случае расторжения Договора со стороны Лицензиата, при отсутствии нарушений условий
              Договора со стороны Лицензиара, возврат вознаграждения не производится.
            </li>
          </ol>
        </li>
        <li>
          <h3>Разрешение споров</h3>
          <ol>
            <li>
              Стороны обязаны прилагать все усилия к тому, чтобы путем переговоров решать все
              разногласия и споры, которые могут возникнуть по настоящему договору, в связи с ним или в
              результате его исполнения.
            </li>
            <li>
              Споры и разногласия, которые невозможно решить путем переговоров, решаются в
              судебном порядке с обязательным соблюдением претензионного порядка. Срок направления
              претензии 14 (четырнадцать) календарных дней.
            </li>
            <li>
              В случае недостижения согласия путем переговоров спор передается на рассмотрение в
              Арбитражный суд Самарской области.
            </li>
          </ol>
        </li>
        <li>
          <h3>Прочие условия</h3>
          <ol>
            <li>
              Договор вступает в силу с момента активации учетной записи, и действует на протяжении срока действия прав
              Лицензиата на использование Сервиса в рамках настоящего Договора, либо до момента расторжения Договора.
            </li>
            <li>
              Лицензиар обязуется информировать Лицензиата об изменениях (дополнениях) по условиям договора-оферты,
              публикуя новую редакцию в сети Интернет по адресу: <Link to={"/"}>https://paraplancrm.ru</Link>. Изменения
              и дополнения к настоящему Договору вступают в силу с момента их опубликования.
            </li>
            <li>
              Лицензиат понимает и согласен с тем, что, если он пользуется Сервисом после даты внесения соответствующих
              изменений в настоящий Договор, это будет расцениваться как факт принятия Лицензиатом измененных условий
              Договора.
            </li>
            <li>
              Лицензиат вправе отказаться от принятия изменений и дополнений, что означает отказ Лицензиата от
              пролонгации использования Сервиса. Право Лицензиата пользования Сервисом сохраняется до истечения того
              срока использования Сервиса, который был оплачен Лицензиатом на момент публикации изменений и дополнений.
            </li>
            <li>
              Во всем, что не оговорено в настоящем Договоре, Стороны руководствуются действующим законодательством РФ.
            </li>
            <li>
              Если какое-либо из положений Договора становится недействительным, это не затрагивает действительности
              остальных положений Договора.
            </li>
            <li>
              При изменении наименования, адреса, банковских реквизитов или реорганизации Стороны информируют друг друга
              в письменном виде или через средства электронной почты в трехдневный срок.
            </li>
            <li>
              Не вступая в противоречие с условиями Оферты, Лицензиар и Лицензиат вправе в любое время оформить Договор
              в форме письменного двухстороннего документа.
            </li>
          </ol>
        </li>
        <li>
          <h3>Условие обработки и использования персональных данных</h3>
          <ol>
            <li>
              Принимая условия настоящего Договора, Лицензиат в соответствии с Федеральным
              законом № 152-ФЗ «О персональных данных» от 27.07.2006 года, действуя свободно, своей
              волей и в своем интересе, выражает свое согласие на: предоставление своих персональных
              данных, а также данных своих сотрудников, клиентов, контрагентов, включающих Фамилию,
              Имя, Отчество, адрес электронной почты, номер контактного телефона, дату рождения, регион,
              город, организацию, должность, а также иные персональные данные, включая изображение, для
              их обработки Лицензиаром.
            </li>
            <li>
              Цель обработки персональных данных:
              <ol>
                <li>
                  предоставление Лицензиату права использования Сервиса, а также возможности
                  использования полезного функционала Сервиса;
                </li>
                <li>
                  предоставление Лицензиату возможности добавлять в Сервис пользовательские компоненты
                  (разработанные и предлагаемые к установке третьими лицами расширения (компоненты),
                  предоставляющие доступ к информации или сервисам третьих лиц, либо осуществляющие
                  переход на ресурсы третьих лиц), либо осуществлять с их помощью переход на ресурсы третьих
                  лиц;
                </li>
                <li>
                  направление уведомлений, касающихся использования Сервиса;
                </li>
                <li>
                  подготовка и направление ответов на запросы Лицензиата;
                </li>
                <li>
                  направление информации о мероприятиях, проводимых Лицензиаром;
                </li>
                <li>
                  направление информации о продуктах и услугах Лицензиара.
                </li>
              </ol>
            </li>
            <li>
              Перечень действий с персональными данными, на которые Лицензиат выражает свое
              согласие: сбор, систематизация, накопление, хранение, уточнение (обновление, изменение),
              использование, обезличивание, а так же осуществление любых иных действий,
              предусмотренных действующим законодательством РФ как неавтоматизированными, так и
              автоматизированными способами.
            </li>
            <li>
              Лицензиар обязуется принимать все необходимые меры, зависящие от него, для защиты
              указанных выше персональных данных от неправомерного доступа или раскрытия.
            </li>
            <li>
              Настоящее согласие действует в течение всего срока действия Лицензионного соглашения и срока использования
              Лицензиатом Сервиса, включая срок резервного хранения информации после окончания срока действия прав
              Лицензиата (п. 4.3.3. Договора). Лицензиат понимает и соглашается с тем, что в случае отзыва данного
              согласия он лишается права использовать Сервис.
            </li>
            <li>
              Лицензиат соглашается на получение посредством электронной почты, адрес которой он указывает при
              регистрации, рекламно-информационных сообщений, касающихся продукции и услуг Лицензиара и его партнеров.
            </li>
          </ol>
        </li>
        <li>
          <h3>Адреса и банковские реквизиты лицензиара</h3>
          <p>
            <b>ООО «Холмонт Бизнес Решения»</b><br/>
            Адрес: 445030, Самарская область, г. Тольятти, улица 40 лет Победы, дом 41, офис 1
          </p>
          <p>
            ИНН 6321416763<br/>
            КПП 632101001<br/>
            ОГРН 1166313133293
          </p>
          <p>
            Банк: ФИЛИАЛ "НИЖЕГОРОДСКИЙ" АО "АЛЬФА-БАНК"<br/>
            БИК: 042202824<br/>
            к/с 30101810200000000824<br/>
            р/с: 40702810129180006023
          </p>
          <p>
            Генеральный директор: Онучин А.В.
          </p>
        </li>
      </ol>
    </TextPageComponent>
  </Layout>
)

export default AgreementPage
